<template>

  <b-card no-body>
    <b-card-header>
      <b-card-title>GIA HẠN DỊCH VỤ</b-card-title>
    </b-card-header>
    <b-card-body>

      <validation-observer
        v-if="plan"
        ref="refForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <div class="d-flex flex-column justify-space-between my-1">
                <div class="font-small-4 font-weight-bolder text-uppercase text-primary">
                  1. Thông tin gói
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Tên gói:</span>
                  <span>{{ plan.name }}</span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Số phòng tối đa theo gói: (1)</span>
                  <span>{{ plan.maxSlots > 0 ? plan.maxSlots : 'Không giới hạn' }}</span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Giá theo tháng: (2)</span>
                  <span>{{ Number(plan.monthlyPrice).toLocaleString() }} đ</span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Giá theo năm: (3)</span>
                  <span>{{ Number(plan.annualPrice).toLocaleString() }} đ</span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Số tháng: (4)</span>
                  <!-- <span>{{ Number(itemLocal.month).toLocaleString() }} tháng</span> -->
                  <validation-provider
                    #default="{ errors }"
                    name="month"
                    rules="required"
                  >
                    <b-form-group>
                      <b-form-spinbutton
                        id="sb-step"
                        v-model="itemLocal.month"
                        step="6"
                        min="6"
                        max="120"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Giá gói (5):</span>
                  <span>{{ plan.name }}</span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Mua thêm phòng: (6)</span>
                  <!-- <span>{{ Number(itemLocal.moreSlots).toLocaleString() }}</span> -->
                  <validation-provider
                    #default="{ errors }"
                    name="moreSlots"
                    rules="required"
                  >
                    <b-form-group>
                      <b-form-spinbutton
                        id="moreSlots"
                        v-model="itemLocal.moreSlots"
                        step="10"
                        min="0"
                        max="100"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Giá mua thêm phòng: (7) = (5) * (6) / 10</span>
                  <span>{{ Number(plan.blockPrice).toLocaleString() }} đ/10 phòng</span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Tổng số đơn vị thuê: (8) = (1) + (6)</span>
                  <span>{{ plan.maxSlots + itemLocal.moreSlots }}</span>
                </b-list-group-item>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>Thành tiền: (9) = (5) + (7)</span>
                  <span>{{ Number(amount).toLocaleString() }} đ</span>
                </b-list-group-item>

              </b-list-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
            >
              <div class="d-flex flex-column justify-space-between my-1">
                <div class="font-small-4 font-weight-bolder text-uppercase text-primary">
                  2. Xuất hóa đơn GTGT
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <b-form-checkbox
                id="vat"
                v-model="itemLocal.vat"
                name="checkbox"
              >
                Xuất hóa đơn GTGT cho đơn hàng này! Resident sẽ xuất HÓA ĐƠN ĐIỆN TỬ
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row
            v-if="itemLocal.vat"
            class="pt-1"
          >
            <b-col

              cols="12"
            >
              <b-alert
                show
                variant="danger"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  <span class="ml-25">Vui lòng điền ĐÚNG và ĐỦ thông tin xuất hóa đơn theo Giấy phép đăng ký kinh doanh của tổ chức/doanh nghiệp, Resident sẽ sử dụng thông tin quý khách đã nhập để xuất hóa đơn.
                    Trường hợp quý khách không nhập thông tin hóa đơn, Resident sẽ xuất hóa đơn theo thông tin cá nhân người mua hàng.</span>
                </div>
              </b-alert>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tên người nhận"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nguyễn Nam Phong"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tên doanh nghiệp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="company"
                  rules="required"
                >
                  <b-form-input
                    id="company"
                    v-model="itemLocal.company"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Công ty Cổ phần Công nghệ ODOOR Việt Nam"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email nhận hóa đơn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john.doe@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Số điện thoại"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="itemLocal.phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0868987355"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Mã số thuế"
              >
                <validation-provider
                  #default="{ errors }"
                  name="taxNumber"
                  rules="required"
                >
                  <b-form-input
                    id="taxNumber"
                    v-model="itemLocal.taxNumber"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0109154368"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Địa chỉ theo GPKD"
              >
                <validation-provider
                  #default="{ errors }"
                  name="address"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="itemLocal.address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Hà Nội"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
            >
              <div class="d-flex flex-column justify-space-between my-1">
                <div class="font-small-4 font-weight-bolder text-uppercase text-primary">
                  3. Phương thức thanh toán
                </div>
              </div>
            </b-col>
            <b-col>

              <b-form-radio
                v-model="itemLocal.paymentMethod"
                name="payment-method"
                :value="1"
                class="custom-control-primary mb-1"
              >
                Thanh toán Online (Kích hoạt tự động)
              </b-form-radio>
              <b-form-radio
                v-model="itemLocal.paymentMethod"
                name="payment-method"
                :value="2"
                class="custom-control-primary"
              >
                Chuyển khoản ngân hàng (Kích hoạt chậm)
              </b-form-radio>
            </b-col>

            <b-col
              v-if="itemLocal.paymentMethod === 2"
              cols="12"
              class="mt-1"
            >
              <b-alert
                show
                variant="primary"
              >
                <div class="alert-body">
                  <span>Thông tin chuyển khoản:<br></span>
                  <span>STK: <strong>216017889</strong><br></span>
                  <span>Ngân hàng: <strong>VPBANK</strong><br></span>
                  <span>Chủ tài khoản: <strong>Nguyễn Nam Phong</strong><br><br></span>
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  <span class="ml-25">Ấn “Gia hạn ngay" để tạo đơn hàng và thực hiện chuyển khoản theo hướng dẫn sẽ được gửi qua email/Zalo ZNS...
                    Quý khách vui lòng chuyển đủ số tiền theo đơn hàng. Phí chuyển khoản do Quý khách trả (nếu có)..
                    Resident khuyến nghị Quý khách Thanh toán Online để giao dịch được thực hiện thuận tiện, nhanh chóng!</span>
                </div>
              </b-alert>
            </b-col>

          </b-row>
          <hr>

          <b-button
            class="mt-2"
            variant="primary"
            block
            type="submit"
            :disabled="invalid"
          >
            Gia hạn ngay
          </b-button>
        </b-form>

      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormCheckbox,
  BAlert,
  BFormRadio,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BForm,
  BButton,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';

import { required, email, agreement } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
// eslint-disable-next-line import/no-cycle
import useRenew from './useRenew';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormSpinbutton,
    BFormCheckbox,
    BAlert,
    BFormRadio,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BForm,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  setup(props, { emit }) {
    const { refFormObserver } = formValidation();

    const {
      itemLocal,
      plan,
      amount,
      resetItemLocal,
      resetModal,
      fetchPlanDetail,
      onSubmit,
    } = useRenew(props, emit, refFormObserver);

    return {
      itemLocal,
      plan,
      amount,
      resetItemLocal,
      resetModal,
      fetchPlanDetail,
      onSubmit,
    };
  },
  data() {
    return {
      // validator
      required,
      email,
      agreement,

    };
  },
  created() {
    this.fetchPlanDetail();
  },
};
</script>
